<template>
    <section class="section-resultado scroll-none">
        <div class="row mx-0">
            <div class="col my-1">
                <div class="row d-md-none d-lg-none">
                    <div class="col search-input">
                        <el-input v-model="buscar" prefix-icon="icon-search" size="small" placeholder="Buscar producto" class="br-20" @input="toGoQueryInvitado" />
                    </div>
                </div>
                <div class="row mx-0 mt-2">
                    <div class="col f-28 text-general f-600">
                        <p>Resultados de la búsqueda "{{ query }}" </p>
                        <div class="like-bar mb-2" />
                    </div>
                </div>
                <div class="row mx-0 overflow-auto custom-scroll" style="height:calc(100vh - 204px)">
                    <div class="col-12 px-0">
                        <div v-if="productos.length > 0" class="row mx-0 px-3 pt-3">
                            <invitado-producto v-for="(producto, index) in productos" :key="index" :producto="producto" />
                        </div>
                        <div v-else class="row mx-0 px-3 pt-3">
                            <contentLoaderQueryProduct v-show="loading" class="col" />
                            <div v-show="!loading" class="a-center j-center text-center w-100 mt-5">
                                <img :src="funImagenGeneral(45)" class="obj-cover " alt="" width="350" height="350" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section-footer />
    </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Service from '~/services/invitado/invitado'

export default {
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.route = from.name;
        });
    },
    data(){
        return{
            loading: true,
            productos: [],
            route: null,
            buscar:''
        }
    },

    computed: {
        ...mapGetters({
            tienda: 'invitado/tienda',
            query: 'buscadores/getQueryProductInvitado',
            isInvitado:'home/isInvitado',
        }),
    },

    watch: {
        query(){
            this.searchProducts();
        },
    },

    mounted(){
        this.searchProducts();
    },

    methods: {
        async searchProducts(){
            try {
                if(_.isEmpty(this.query)){
                    return;
                }

                if(!this.tienda.id_tienda){
                    await this.$store.dispatch('invitado/getTiendaDefault')
                }
                
                const params = {
                    idTienda: this.tienda.id_tienda,
                    query: this.query
                };
                const {data} = await Service.searchProducts(params);
                this.productos = data.productos;
            } catch(e){
                this.errorCatch(e)
            } finally{
                this.loading = false;
            }
        },
        async toGoQueryInvitado(){
            const search = async() => {
                if(this.buscar.length > 2){
                
                    this.$store.commit('buscadores/setQueryProductInvitado', this.buscar);

                    let pathname = window.location.pathname;
                
                    if(pathname != '/invitado/busqueda'){
                        this.$router.replace({name:'invitado.buscar.producto'});
                    }
                
                } else if(this.buscar.length == 0){
                
                    this.$store.commit('buscadores/setQueryProductInvitado', '');
                    setTimeout(() => {
                        this.$router.push({name:'invitado'});
                    },1000);
                }       
            }
            await this.delay(search);
        },
    }
}
</script>
<style lang="scss" scoped>
.section-resultado{
	height: calc(100vh - 96px);
	overflow: auto;
}
@media (max-width: 768px){
.section-resultado{
	height: calc(100vh - 167px) !important;
}
}
</style>